<template>
  <div>
    <!-- CARDS -->
    <b-row v-if="isListTypeCard" class="d-flex mx-25">
      <!-- DRAGGABLE CARDS -->
      <div v-if="isEditing">
        <draggable
          v-model="itemsData"
          :list="itemsData"
          :move="checkMove"
          class="w-100"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group class="d-flex flex-wrap justify-content-around w-100">
            <b-col
              v-for="item in itemsData"
              :key="item.key"
              class="px-50 d-flex justify-content-center d-sm-block"
            >
              <b-card
                v-if="canList(item)"
                class="cards my-1 mb-2 d-flex mx-auto justify-content-center draggable-card"
              >
                <div class="media-row flex-column d-flex justify-content-center text-dark">
                  <div
                    class="p-0"
                    :class="
                      mediaType === 'audio' || mediaType === 'photo'
                        ? 'square'
                        : 'rectangle'"
                  >
                    <SafeImg
                      class="thumbnail"
                      :src="getImageSrc(item)"
                      :placeholder="placeholderItems"
                      :alt=" title(item.name)"
                      :retry="5000"
                    />
                  </div>

                  <p class="title m-0 mt-1 text-ellipsis-lines-2 p-0">
                    {{ title(item.name) }}
                  </p>
                  <!--div class="d-flex">
                    <div>
                      <UserAvatar
                        v-if="item.createdBy"
                        :user="item.createdBy"
                        size="18"
                        class="mr-50 float-right"
                      />
                    </div>
                    <div class="d-block media-text">
                      <p
                        v-if="item.createdBy"
                        class="subtitle m-0 text-secondary text-ellipsis-lines-"
                      >
                        {{ item.createdBy.name }} {{ item.createdBy.surname }}
                      </p>
                      <p v-else class="text-muted author m-0">
                        {{ $t("media.list.error-author") }}
                      </p>
                    </div>
                  </div-->

                  <!-- ACTIONS -->
                  <div class="small text-muted mt-50">

                  </div>
                </div>
              </b-card>
            </b-col>
          </transition-group>
        </draggable>
      </div>

      <!-- NORMAL - NON DRAGGABLE -->
      <div v-else>
        <transition-group class="d-flex flex-wrap justify-content-around w-100">
          <b-col v-for="item in itemsData" :key="item.key">
            <b-card
              v-if="canList(item)"
              class="cards my-1 mb-2 d-flex mx-auto justify-content-center"
              @click="openPreview(item)"
            >
              <b-link
                :to="getMediaDetailLink(item)"
                class="media-row flex-column d-flex justify-content-center text-dark"
              >
                <div
                  class="p-0"
                  :class="
                    mediaType === 'audio' || mediaType === 'photo'
                      ? 'square'
                      : 'rectangle'"
                >
                
                  <SafeImg
                    class="thumbnail"
                    :src="getImageSrc(item)"
                    :placeholder="placeholderItems"
                    :alt=" title(item.name)"
                    :retry="5000"
                  />
                </div>

                <p class="title m-0 mt-1 text-ellipsis-lines-2 p-0">
                  {{ title(item.name) }}
                </p>
                <div class="d-flex">
                  <div>
                    <UserAvatar
                      v-if="item.createdBy"
                      :user="item.createdBy"
                      size="18"
                      class="mr-50 float-right"
                    />
                  </div>
                  <div class="d-block media-text">
                    <p
                      v-if="item.createdBy"
                      class="subtitle m-0 text-secondary text-ellipsis-lines-"
                    >
                      {{ item.createdBy.name }} {{ item.createdBy.surname }}
                    </p>
                    <p v-else class="text-muted author m-0">
                      {{ $t("media.list.error-author") }}
                    </p>
                  </div>
                </div>
                <div class="small text-muted mt-50">
                  <!-- Completed -->
                  <small v-if="item.isCompletable" class="mr-50">
                    <feather-icon
                      size="16"
                      :class="item.completedByMember ? 'item-completed' : ''"
                      class="profile-icon mr-25"
                      icon="CheckCircleIcon"
                    />
                  </small>
                  <small>
                    <feather-icon
                      size="16"
                      :class="item.likedByMember ? 'heart-fill' : ''"
                      class="profile-icon mr-25"
                      icon="HeartIcon"
                      @click.stop.prevent="like(item)"
                    />

                    {{ item.totalLikes }}
                  </small>

                  <small class="mx-50">
                    <feather-icon
                      role="button"
                      size="16"
                      icon="MessageSquareIcon"
                      class="mr-25"
                    />
                    {{ item.commentCount || 0 }}
                  </small>

                  <span>
                    <feather-icon size="16" icon="ClockIcon" class="mr-25" />
                    {{ date(item) }}
                  </span>
                </div>
              </b-link>
            </b-card>
          </b-col>
        </transition-group>
      </div>
    </b-row>

    <!-- TABLE -->
    <div v-else class="list-group mt-1 overflow-auto">
      <b-table
        v-if="itemsData && itemsData.length > 0"
        class="position-relative"
        :items="itemsData2"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        sort-icon-left
        empty-text="No matching records found"
      >
        <!-- PREVIEW -->
        <template #cell(placeholder)="{ item }">
          <b-link
            v-if="canList(item)"
            :to="{
              name: `${mediaType}-details`,
              params: {
                itemKey: item.key,
                communityId: $route.params.communityId,
              },
            }">
            <div class="placeholder pl-1">
              <div
                class="list-img mr-1"
                :class="
                  mediaType === 'audio' || mediaType === 'photo'
                    ? 'square-list'
                    : 'rectangle-list'"
              >
                <SafeImg
                  class="thumbnail"
                  :src="getImageSrc(item)"
                  :placeholder="placeholderItems"
                  :alt=" title(item.name)"
                  :retry="5000"
                />
              </div>
            </div>
          </b-link>
        </template>
        
        <!-- TITLE -->
        <template #cell(title)="{ item }">
          <b-link
            v-if="canList(item)"
            :to="{
              name: `${mediaType}-details`,
              params: {
                itemKey: item.key,
                communityId: $route.params.communityId,
              },
            }">
            <h5 class="title html-text-ellipsis-2 pl-1">
              {{ title( item.name) }}
            </h5>
          </b-link>
        </template>
        
        <!-- AUTHOR -->
        <template #cell(author)="{ item }">
          <b-link
            v-if="canList(item)"
            :to="{
              name: `${mediaType}-details`,
              params: {
                itemKey: item.key,
                communityId: $route.params.communityId,
              },
            }">
            <div class="author ml-1">
              <p
                v-if="item.createdBy"
                class="text-secondary m-0 text-ellipsis-lines-2"
              >
                {{ item.createdBy.name }} {{ item.createdBy.surname }}
              </p>
              <p v-else class="text-muted author m-0">
                {{ $t("media.list.error-author") }}
              </p>
            </div>
          </b-link>
        </template>
        
        <!-- CREATION -->
        <template #cell(creation)="{ item }">
          <b-link
            v-if="canList(item)"
            :to="{
              name: `${mediaType}-details`,
              params: {
                itemKey: item.key,
                communityId: $route.params.communityId,
              },
            }">
            <span class="text-muted d-flex date small ml-1">
              <feather-icon size="16" icon="ClockIcon" class="mr-25" />
              {{ date(item) }}
            </span>
          </b-link>
        </template>

        <!-- ACTIONS -->
        <template #cell(interactive)="{ item }">
          <div 
            class="d-flex"
            :class="item.isCompletable ? 'interactive2' : 'interactive'"
          >
            <div>
              <b-card-text class="small text-muted">
                <!-- Complete -->
                <small 
                  v-if="item.isCompletable" 
                  class="download"
                  :title="item.completedByMember ? $t('social.completed') : $t('social.complete')">
                  <feather-icon
                    size="16"
                    :class="item.completedByMember ? 'item-completed' : ''"
                    class="profile-icon mr-25"
                    icon="CheckCircleIcon"
                  />
                </small>
                <!-- Like -->
                <small 
                  class="download"
                  :class="item.isCompletable ? ' ml-1' : ''">
                  <feather-icon
                    size="16"
                    :class="item.likedByMember ? 'heart-fill' : ''"
                    class="profile-icon mr-25"
                    icon="HeartIcon"
                    @click.stop.prevent="like(item)"
                  />
                  {{ item.totalLikes }}
                </small>
                <!-- Comment -->
                <small class="download ml-1">
                  <feather-icon
                    role="button"
                    size="16"
                    icon="MessageSquareIcon"
                    class="mr-25"
                  />
                  {{ item.commentCount || 0 }}
                </small>
              </b-card-text>
            </div>
            <!-- Download -->
            <small class="download ml-1">
              <b-button
                v-if="item.downloadMediaURL"
                variant="link"
                class="p-0 m-0"
                :href="item.downloadMediaURL"
                target="_blank"
              >
                <feather-icon icon="DownloadIcon" size="16" />
              </b-button>
            </small>
          </div>
        </template>
      </b-table>
    </div>  <!-- END table -->

    <!-- Media preview -->
    <b-modal
      v-model="isPreviewModalOpened"
      :title="$t('common.preview')"
      :cancel-title="$t('action.cancel')"
      size="lg"
    >
      <media-preview-modal 
        :media="selectedItem"
      />
    </b-modal>

  </div>
</template>

<script>
// import BaseVerticalContainerRefactored from '@core/components/containers/base/BaseVerticalContainerRefactored.vue';
// import MediaUploadModal from '@/views/apps/media/components/modal/MediaUploadModal.vue';
import {
  PlaceholdersImage,
  PlaceholdersImageItems,
} from '@/views/apps/media/constants/MediaType';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
// import SafeImg from '@core/components/safe-img/SafeImg.vue';
// import UserAvatar from '@core/components/user/UserAvatar.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import draggable from 'vuedraggable';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'MediaVerticalList',
  components: {
    draggable,
    SafeImg: () => import('@core/components/safe-img/SafeImg.vue' /* webpackChunkName: "SafeImg" */),
    UserAvatar: () => import('@core/components/user/UserAvatar.vue' /* webpackChunkName: "UserAvatar" */),
    MediaPreviewModal: () => import('@/views/apps/media/components/modal/MediaPreviewModal.vue' /* webpackChunkName: "MediaPreviewModal" */),
  },
  props: {
    mediaType: {
      type: String,
      required: true,
    },
    perPage: {
      type: Number,
      default: 4,
    },
    isListTypeCard: {
      type: Boolean,
      required: true,
    },
    itemsData: {
      type: Array,
      default: [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      lastLoadedPage: 1,
      dragging: false,
      actualItems: [],
      customNames: [],
      fields: [],
      selectedItem: {},
      isPreviewModalOpened: false,
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return PlaceholdersImage(this.mediaType);
    },
    placeholderItems() {
      return PlaceholdersImageItems(this.mediaType);
    },
    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.key.includes(this.mediaType)) {
          return item.name;
        }
      });
      return app[0];
    },
    canShowCollaborator() {
      return this.widget?.customization?.collaboratorDisplay;
    },
    notAtHomeView() {
      return this.$route.name != "Resumen";
    },
    classifiersByMediaType() {
      return this.$store.getters.classifiers[`media-${this.mediaType}`];
    },
    classifiers() {
      // console.log('this.classifiersByMediaType', this.classifiersByMediaType)
      if (this.classifiersByMediaType) {
        return this.classifiersByMediaType.unpaginated;
      }
      return [];
    },
  },
  watch: {
    fields(value) {
      return value
    },
    itemsData(value) {
      // console.log('watching itemsData');
      this.itemsData2 = value.map(item => item);
      if (this.notAtHomeView) {
        this.fetchClassifiers(true)
        this.createClassifiersColumns()
        this.createFieldsColumns()
      }
    }
  },
  async created() {
    this.itemsData2 = this.itemsData.map(item => item);
    // console.log('this.itemsData', this.itemsData);
    // console.log('this.itemsData2', this.itemsData2);
    this.fields =[
        {
          key: 'placeholder',
          label: this.$t("media.list.placeholder"),
        },
        {
          key: 'title',
          label: this.$t("media.list.title"),
        },
        // {
        //   key: 'author',
        //   label: this.$t("media.list.author"),
        // },
        {
          key: 'creation',
          label: this.$t("media.list.creation-date"),
        },
        {
          key: 'interactive',
          label: this.$t("media.list.interactions"),
          thClass: 'pl-1',
        },
      ];
    if (this.notAtHomeView) {
      await this.fetchClassifiers(true)
      this.createClassifiersColumns()
      this.createFieldsColumns()
    }
  },
  methods: {
    createClassifiersColumns() {
      // console.log('createClassifiersColumns this.classifiers', this.classifiers);
      if (this.classifiers?.length > 0) {
        let typeNames = this.classifiers.map((item) => item.typeName);
        typeNames = [...new Set(typeNames)];
        // console.log('typeNames', typeNames)

        this.dataClassifiers();
        
        return typeNames.map((item, index) => {
          this.fields.splice(2, 0, {
            key: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            label: item[this.locale] ? (item[this.locale].length > 30 ? `${item[this.locale].substr(0, 30)}...`:  item[this.locale]) : Object.values(item)[0],
            sortable: false,
            thClass: 'pl-50',
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor }; font-weight: 600` }
                }
              });
              return style;
            },
            thAttr: {
              'v-b-tooltip': 'hover',
              title: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale].substr(0,30) : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              // console.log('formatter value', value);
              return value;
            },
          });
        });
      }
    },
    createFieldsColumns() {
      this.itemsData2.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      this.customNames.map((item, index) => {
        this.fields.splice(2, 0, {
          key: item,
          label: item.length > 30 ? `${item.substr(0,30)}...` : item,
          sortable: false,
          thClass: 'pl-50',
          thAttr: {
            'v-b-tooltip': 'hover',
            title: item,
          },
          formatter: (value) => {
            if (typeof value === 'boolean') {
              if (value === true) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.true',
                );
              }
              if (value === false) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.false',
                );
              }
            }
            return value;
          },
        });
      });
    },
    dataFields() {
      this.itemsData.map((n, index) => {
        n?.custom?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.itemsData2[index][typeName] = i.value;
        });
      });
    },
    dataClassifiers() {
      this.itemsData2 = this.itemsData.map((item) => item);
      this.itemsData.map((n, index) => {
        n.classifiers?.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.itemsData2[index][typeName])) {
                if (this.itemsData2[index][typeName].includes(i.name)) {
                  return;
                }
                this.itemsData2[index][typeName].push(i.name);
              } else {
                this.itemsData2[index][typeName] = [];
                this.itemsData2[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },
    title(name) {
      const translateName = translateTranslationTable(this.currentLocale, name);
      return translateName?.replaceAll('_', ' ');
    },
    canList(item) {
      return (
        this.loggedUser.key === item.createdBy?.key
        || this.isStaff
        || checkPermissions(
          'index',
          `${this.mediaType}`,
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    date(item) {
      return this.$moment(item.createdAt).fromNow();
    },
    translateTranslationTable,
    getImageSrc(item) {
      return getImageResource(item.thumbnailURL) || item.url;
    },
    like(item) {
      this.toggleLike(item);
    },
    async toggleLike(mediaItem) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'media',
        morphType: this.mediaType,
        key: mediaItem.key,
        storedKey: this.mediaType,
      });
    },
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.itemsData];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
      this.$emit('moveItems', this.actualItems);
    },
    async fetchClassifiers(force = false) {
      // console.log('Fetch classifiers');
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: `media-${this.mediaType}`,
        forceAPICall: force,
        page: 1,
        requestConfig: { 
          morphType: this.mediaType, 
          count: 1000 
        },
      });
    },
    getMediaDetailLink(item) {
      if(this.isPreview){
        return null;
      }
      console.log('this.$route.params.communityId', this.$route.params.communityId);
      const route = {
                name: `${this.mediaType}-details`,
                params: {
                  itemKey: item.key,
                  communityId: this.$route.params.communityId,
                },
              };
      console.log('route', route)
      return route;
    },
    openPreview(item) {
      if(this.isPreview){
        this.selectedItem = item;
        this.isPreviewModalOpened = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.thumbnail {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f0f0f0;
}
.thumbnail-container {
  width: 210px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f0f0f0;
}
.list-img {
  height: 52px;
  border-radius: 8px;
  background-color: #f0f0f0;
}
.square-list {
  width: 57px;
}
.rectangle-list {
  width: 93px;
}
.square {
  height: 210px;
}
.rectangle {
  height: 140px;
}
.placeholder {
  min-width: 92px;
  width: 92px;
}
.cards-container {
  width: 100%;
}
.cards {
  width: 250px;
  max-width: 100%;
  min-width: 250px;
  min-height: 240px;
  box-shadow: 0px 0px 15px -5px;
}
.card-body {
  padding: 1.2rem;
}
.title {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.author {
  width: 180px;
}
.heart-fill {
  color: #da5f5b;
}
.liked {
  z-index: 5;
}
.list-item {
  border-color: #eeeeee;
}
.info {
  width: 220px;
  max-width: 220px;
}
.download {
  width: 20px;
}
.date {
  width: 140px;
  min-width: 140px;
}
.interactive {
  min-width: 115px;
  width: 115px;
}
.interactive2 {
  min-width: 150px;
  width: 150px;
}
.container {
  max-width: 100%;
}

// using 'down' media query to override bootstrap's b-media
@include media-breakpoint-down(sm) {
  .media-row {
    align-items: center;
    &::v-deep {
      .media-aside {
        align-self: center !important;
        margin-right: 0;
        img {
          margin-right: 0 !important;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .container {
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .cards {
    max-width: 46%;
  }
}
@include media-breakpoint-up(lg) {
  .cards {
    max-width: 31%;
  }
}
.item-completed {
  color: green !important;
  font-weight: 700;
  background-color: #b4f5b4;
}
.draggable-card {
  background-color: #f7fcff;
  cursor: grab;
  border-radius: 15px;

  :hover {
    background-color: #e7f3fa;
  }
}
</style>
